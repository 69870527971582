@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  height: 100vh;
}

body {
  height: 100%;
}

#root {
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
}

main {
  flex: 2;
}

::-webkit-scrollbar {
  height: 0.5rem !important;
}

::-webkit-scrollbar-thumb {
  background-color: #ff7510 !important;
}

::-webkit-scrollbar-button {
  width: 0.5%;
}

.border-first::before,
.border-first::after,
.border-second::before,
.border-second::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: transparent;
  transition: 0.9s;
}

.border-first::before {
  top: 113px;
  left: -8px;
  border-left: 5px solid transparent;
  border-radius: 5px 16px 0px  0px;
}
.border-first::after {
  top: -8px;
  right: -8px;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-radius: 5px 16px 0px 0px;
}

.border-second::before {
  bottom: -8px;
  left: -8px;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  border-radius: 0px 16px 0px 16px;
}
.border-second::after {
  bottom: -8px;
  right: -8px;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  border-radius: 16px 0px 16px 0px;
}


.border-first:hover::before,
.border-first:hover::after,
.border-first:hover .border-second::before,
.border-first:hover .border-second::after  {
  border-color: black !important;
  width: 55%;
  height: 53%;
}

@layer utilities {
  .border-first-dark::before,
  .border-first-dark::after,
  .border-second-dark::before,
  .border-second-dark::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: transparent;
    transition: 0.9s;
    /* -webkit-mix-blend-mode: hue; */
  }

  .border-first-dark::before {
    top: 113px;
    left: -8px;
    border-left: 5px solid transparent;
    border-radius: 5px 0px 0px  0px;
  }
  .border-first-dark::after {
    top: -8px;
    right: -8px;
    border-top: 5px solid transparent;
    border-right: 5px solid transparent;
    border-radius: 5px 16px 0px 0px;
  }

  .border-second-dark::before {
    bottom: -8px;
    left: -8px;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-radius: 0px 16px 0px 16px;
  }
  .border-second-dark::after {
    bottom: -8px;
    right: -8px;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    border-radius: 16px 0px 16px  0px;
  }


  .border-first-dark:hover::before,
  .border-first-dark:hover::after,
  .border-first-dark:hover .border-second-dark::before,
  .border-first-dark:hover .border-second-dark::after  {
    border-color: white !important;
    width: 55%;
    height: 55%;
  }
}

